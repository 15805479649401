define("gvp-portal/pods/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    burgerActive: false,
    actions: {
      invalidateSession: function invalidateSession() {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});