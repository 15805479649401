define("gvp-portal/pods/settings/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6OvXH+6A",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[7,\"aside\"],[12,\"class\",[28,[\"settings-users-list \",[27,\"if\",[[27,\"or\",[[27,\"eq\",[[23,[\"router\",\"currentRouteName\"]],\"settings.users.edit\"],null],[27,\"eq\",[[23,[\"router\",\"currentRouteName\"]],\"settings.users.create\"],null]],null],\"disabled\"],null]]]],[9],[0,\"\\n\"],[4,\"settings-list\",null,[[\"title\",\"isLoading\",\"onSearch\",\"search\",\"values\",\"meta\",\"selected\",\"addText\",\"onAdd\",\"onPageChanged\",\"onSelect\",\"searchPlaceholder\"],[\"User accounts\",[23,[\"isLoading\"]],[27,\"action\",[[22,0,[]],\"search\"],null],[23,[\"q\"]],[23,[\"users\"]],[23,[\"meta\"]],[27,\"hash\",null,[[\"id\"],[[23,[\"selectedUser\"]]]]],\"Add New\",[27,\"action\",[[22,0,[]],\"add\"],null],[27,\"action\",[[22,0,[]],\"getPage\"],null],[27,\"action\",[[22,0,[]],\"select\"],null],\"Search for a username or email\"]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"first_name\"]],false],[0,\" \"],[1,[22,1,[\"last_name\"]],false],[0,\" \"],[7,\"i\"],[9],[0,\"(\"],[1,[22,1,[\"username\"]],false],[0,\")\"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"settings-users-detail\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gvp-portal/pods/settings/users/template.hbs"
    }
  });

  _exports.default = _default;
});