define("gvp-portal/pods/print/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wz6Mf6ZS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"id\",\"print-page\"],[9],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"filters\"],[9],[0,\"\\n    \"],[1,[27,\"print/filters-panel\",null,[[\"onChange\",\"filters\"],[[27,\"action\",[[22,0,[]],\"applyFilters\"],null],[27,\"hash\",null,[[\"print_site\",\"document_type\",\"product\",\"issue_day\",\"issue_date_before\",\"issue_date_after\",\"file_generated_after\",\"file_generated_before\"],[[23,[\"print_site\"]],[23,[\"document_type\"]],[23,[\"product\"]],[23,[\"issue_day\"]],[23,[\"issue_date_before\"]],[23,[\"issue_date_after\"]],[23,[\"file_generated_after\"]],[23,[\"file_generated_before\"]]]]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"results\"],[9],[0,\"\\n    \"],[1,[27,\"print/documents-table\",null,[[\"data\",\"meta\",\"isLoading\",\"onPageChange\"],[[23,[\"data\"]],[23,[\"meta\"]],[23,[\"isLoading\"]],[27,\"action\",[[22,0,[]],\"getPage\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gvp-portal/pods/print/template.hbs"
    }
  });

  _exports.default = _default;
});