define("gvp-portal/components/lt-column-resizer", ["exports", "ember-light-table/components/lt-column-resizer"], function (_exports, _ltColumnResizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ltColumnResizer.default;
    }
  });
});