define("gvp-portal/pods/components/print/documents-table/row-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qzKtax6n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"row-toggle\"],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[28,[\"fa \",[27,\"if\",[[23,[\"row\",\"expanded\"]],\"fa-chevron-down\",\"fa-chevron-right\"],null]]]],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"toggle\",[\"expanded\",[23,[\"row\"]]],null]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gvp-portal/pods/components/print/documents-table/row-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});