define("gvp-portal/pods/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cV4uH2ej",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"id\",\"settings-page\"],[9],[0,\"\\n  \"],[7,\"aside\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"settings.account\"],null,{\"statements\":[[0,\"My account\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"session\",\"data\",\"authenticated\",\"profile\",\"role\",\"reference\"]],\"admin\"],null]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"settings.users\"],null,{\"statements\":[[0,\"Security\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"powered\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/images/powered_by_prem.png\"],[11,\"width\",\"120px\"],[11,\"alt\",\"Powered by premonition image\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n  \"],[7,\"section\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gvp-portal/pods/settings/template.hbs"
    }
  });

  _exports.default = _default;
});