define("gvp-portal/pods/settings/users/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "gvp-portal/models/user"], function (_exports, _authenticatedRouteMixin, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      controller.set('user', _user.default.create(Ember.getOwner(this).ownerInjection(), {}));
    }
  });

  _exports.default = _default;
});