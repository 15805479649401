define("gvp-portal/pods/components/print/documents-table/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FBGUR42K",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[23,[\"table\",\"responsiveHiddenColumns\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"label\"]],\"Issue day\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"strong\"],[9],[1,[22,1,[\"label\"]],false],[0,\":\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"moment-format\",[[27,\"get\",[[23,[\"row\"]],[22,1,[\"valuePath\"]]],null],\"dddd\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[22,1,[\"label\"]],\"File timestamp\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"strong\"],[9],[1,[22,1,[\"label\"]],false],[0,\":\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"moment-format\",[[27,\"get\",[[23,[\"row\"]],[22,1,[\"valuePath\"]]],null],\"DD/MM/YYYY\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[22,1,[\"label\"]],\"\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[1,[27,\"print/documents-table/download-links\",null,[[\"value\"],[[27,\"get\",[[23,[\"row\"]],[22,1,[\"valuePath\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gvp-portal/pods/components/print/documents-table/row/template.hbs"
    }
  });

  _exports.default = _default;
});