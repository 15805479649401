define("gvp-portal/pods/print/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    setupController: function setupController(controller) {
      if (!controller.print_site && controller.initialLoad) {
        controller.set('print_site', this.get('session.data.authenticated.profile.primary_print_site'));
      }

      controller.set('initialLoad', false);
      controller.fetchDocuments.perform();
    }
  });

  _exports.default = _default;
});