define("gvp-portal/pods/components/print/documents-table/download-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TGN49lfP",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"a\"],[12,\"href\",[21,\"viewUrl\"]],[11,\"target\",\"_blank\"],[11,\"class\",\"button is-small is-inverted is-primary\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon is-small\"],[9],[1,[27,\"fa-icon\",[\"external-link\"],null],false],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"View\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"a\"],[12,\"href\",[21,\"downloadUrl\"]],[11,\"download\",\"\"],[11,\"class\",\"button is-small is-inverted is-primary\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon is-small\"],[9],[1,[27,\"fa-icon\",[\"download\"],null],false],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Download\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gvp-portal/pods/components/print/documents-table/download-links/template.hbs"
    }
  });

  _exports.default = _default;
});