define("gvp-portal/pods/auth/expired-password/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "gvp-portal/models/password-change"], function (_exports, _unauthenticatedRouteMixin, _passwordChange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    expiredPasswordWorkflow: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.expiredPasswordWorkflow.isValid()) {
        this.transitionTo('index');
      }
    },
    setupController: function setupController(controller) {
      controller.set('passwordChange', _passwordChange.default.create(Ember.getOwner(this).ownerInjection(), {
        current_password: controller.expiredPasswordWorkflow.get('current_password')
      }));
    }
  });

  _exports.default = _default;
});