define("gvp-portal/authenticators/news", ["exports", "ember-simple-auth/authenticators/base", "ember-ajax/errors"], function (_exports, _base, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    expiredPasswordWorkflow: Ember.inject.service(),
    refreshLeeway: 10000,
    //Refresh 10 seconds before expiry
    refreshTokenTimeout: null,
    restore: function restore(data) {
      var _this = this;

      var dataObject = Ember.Object.create(data);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var now = _this.getCurrentTime();

        var jwtPayload = _this.getTokenData(dataObject.get('access_token'));

        var expiresAt = jwtPayload.exp * 1000;

        if (expiresAt > now + _this.refreshLeeway) {
          var wait = expiresAt - now - _this.refreshLeeway;

          if (wait > 0) {
            _this.scheduleAccessTokenRefresh(expiresAt);

            resolve(data);
          } else {
            reject(new Error('Unable to refresh access token'));
          }
        } else {
          reject(new Error('Access token is expired'));
        }
      });
    },
    authenticate: function authenticate(username, password) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.ajax.post('auth/login', {
          data: JSON.stringify({
            username: username,
            password: password
          })
        }).then(function (sessionData) {
          if (sessionData.profile.require_password_change) {
            _this2.expiredPasswordWorkflow.set('access_token', sessionData.access_token);

            _this2.expiredPasswordWorkflow.set('profile', sessionData.profile);

            _this2.expiredPasswordWorkflow.set('current_password', password);

            Ember.run(null, reject, 'Expired Password');
          } else {
            _this2.processAuthResponse(sessionData);

            Ember.run(null, resolve, sessionData);
          }
        }, function (xhr) {
          if ((0, _errors.isUnauthorizedError)(xhr)) {
            Ember.run(null, reject, 'Invalid Credentials');
          } else {
            Ember.run(null, reject, 'Connection Failed');
          }
        }).catch(function () {
          Ember.run(null, reject, 'Connection Failed');
        });
      });
    },
    invalidate: function invalidate() {
      Ember.run.cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      return Ember.RSVP.resolve();
    },
    processAuthResponse: function processAuthResponse(sessionData) {
      var jwtPayload = this.getTokenData(sessionData.access_token);
      var expiresAt = jwtPayload.exp * 1000;
      this.scheduleAccessTokenRefresh(expiresAt);
    },
    refreshAccessToken: function refreshAccessToken() {
      var _this3 = this;

      this.ajax.post('auth/refresh-token').then(function (sessionData) {
        _this3.processAuthResponse(sessionData);

        _this3.trigger('sessionDataUpdated', sessionData);
      }, function () {
        _this3.trigger('sessionDataInvalidated');
      });
    },
    scheduleAccessTokenRefresh: function scheduleAccessTokenRefresh(expiresAt) {
      var now = this.getCurrentTime();
      var wait = expiresAt - now - this.refreshLeeway;

      if (wait > 0) {
        Ember.run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        this._refreshTokenTimeout = Ember.run.later(this, this.refreshAccessToken, wait);
      }
    },
    getCurrentTime: function getCurrentTime() {
      return new Date().getTime();
    },
    getTokenData: function getTokenData(token) {
      var payload = token.split('.')[1];
      var tokenData = decodeURIComponent(window.escape(atob(payload)));

      try {
        return JSON.parse(tokenData);
      } catch (e) {
        return tokenData;
      }
    }
  });

  _exports.default = _default;
});