define("gvp-portal/pods/components/print/documents-table/component", ["exports", "ember-light-table", "moment"], function (_exports, _emberLightTable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dir: 'asc',
    sort: 'file_generated_at',
    table: null,
    old_print_site_mapping: {
      'CH': 'SPC',
      'DA': 'DPC',
      'HO': 'HPC',
      'MU': 'BPC',
      'PE': 'PPC',
      'TO': 'TPC',
      'WE': 'MPC',
      'AD': 'APC',
      'RO': 'RPC',
      'YA': 'YPC',
      'WA': 'WPC'
    },
    columns: Ember.computed(function () {
      var printSiteMapping = this.old_print_site_mapping;
      return [{
        width: '40px',
        sortable: false,
        cellComponent: 'print/documents-table/row-toggle',
        breakpoints: ['mobile', 'tablet_portrait', 'tablet_landscape']
      }, {
        label: 'Print site',
        sortable: false,
        valuePath: 'print_site',
        align: 'center',
        format: function format(value) {
          return printSiteMapping[value] || value;
        }
      }, {
        label: 'Document type',
        sortable: false,
        valuePath: 'document_type'
      }, {
        label: 'Issue day',
        sortable: false,
        valuePath: 'issue_date',
        format: function format(value) {
          return (0, _moment.default)(value).format('dddd');
        },
        breakpoints: ['desktop', 'jumbo']
      }, {
        label: 'Issue date',
        sortable: false,
        valuePath: 'issue_date',
        format: function format(value) {
          return (0, _moment.default)(value).format('DD/MM/YYYY');
        }
      }, {
        label: 'Product ID',
        sortable: false,
        valuePath: 'products',
        format: function format(value) {
          if (value.length === 1) {
            if (value[0].editions && value[0].editions.length === 1) {
              return value[0].id + value[0].editions[0];
            } else {
              return value[0].id;
            }
          } else {
            return value.map(function (p) {
              return p.id;
            }).join(' ');
          }
        }
      }, {
        label: 'File timestamp',
        sortable: false,
        valuePath: 'file_generated_at',
        format: function format(value) {
          return (0, _moment.default)(value).format('YYYY-MM-DD HH:mm:ss');
        },
        breakpoints: ['tablet_landscape', 'desktop', 'jumbo']
      }, {
        label: '',
        valuePath: 'id',
        sortable: false,
        align: 'right',
        breakpoints: ['tablet_landscape', 'desktop', 'jumbo'],
        cellComponent: 'print/documents-table/download-links'
      }];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var table = new _emberLightTable.default(this.columns, this.data, {
        enableSync: true
      });
      this.set('table', table);
    },
    actions: {
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        if (matches.indexOf('desktop') > -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath')
          });
        }
      }
    }
  });

  _exports.default = _default;
});