define("gvp-portal/services/ajax", ["exports", "ember-ajax/services/ajax", "ember-ajax/errors", "ember-local-storage"], function (_exports, _ajax, _errors, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    config: Ember.inject.service(),
    loginFlash: (0, _emberLocalStorage.storageFor)('login-flash'),
    host: Ember.computed.alias('config.api.host'),
    contentType: 'application/json; charset=utf-8',
    headers: Ember.computed('session.data.authenticated', {
      get: function get() {
        var headers = {};
        var jwt = this.get('session.data.authenticated.access_token');

        if (jwt) {
          headers['Authorization'] = 'Bearer ' + jwt;
        }

        return headers;
      }
    }),
    handleResponse: function handleResponse(response_code, response_headers, response_body, request) {
      var result = this._super.apply(this, arguments);

      if ((0, _errors.isUnauthorizedError)(result) && !(request.url.indexOf('auth/login') >= 0 && request.method.toUpperCase() === 'POST') && this.get('session.isAuthenticated')) {
        this.set('loginFlash.message', 'Your session has expired, please re-authenticate.');
        this.session.invalidate();
      } else {
        return result;
      }
    }
  });

  _exports.default = _default;
});