define("gvp-portal/services/expired-password-workflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    current_password: null,
    access_token: null,
    profile: null,
    isValid: function isValid() {
      return Ember.isPresent(this.current_password) && Ember.isPresent(this.access_token) && Ember.isPresent(this.profile) && Ember.isPresent(this.get('profile.username'));
    },
    clear: function clear() {
      this.set('current_password', null);
      this.set('access_token', null);
      this.set('profile', null);
    }
  });

  _exports.default = _default;
});