define("gvp-portal/pods/auth/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    actions: {
      willTransition: function willTransition() {
        this.controller.get('loginFlash').clear();
        return true;
      }
    }
  });

  _exports.default = _default;
});