define("gvp-portal/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mobile: '(max-width: 599px)',
    tablet_portrait: '(min-width: 600px) and (max-width: 899px)',
    tablet_landscape: '(min-width: 900px) and (max-width: 1199px)',
    desktop: '(min-width: 1200px) and (max-width: 1799px)',
    jumbo: '(min-width: 1800px)'
  };
  _exports.default = _default;
});