define("gvp-portal/models/user", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: (0, _emberCpValidations.validator)('presence', true),
    last_name: (0, _emberCpValidations.validator)('presence', true),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    primary_print_site: (0, _emberCpValidations.validator)('presence', true),
    role: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('length', {
      min: 5
    })]
  });

  var _default = Ember.Object.extend(Validations, {
    first_name: null,
    last_name: null,
    email: null,
    enabled: true,
    username: null,
    password: null,
    role: null,
    require_password_change: false,
    primary_print_site: null
  });

  _exports.default = _default;
});