define("gvp-portal/pods/components/print/filters-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'aside',
    classNames: ['print-filters-panel'],
    session: Ember.inject.service(),
    print_site: null,
    document_type: null,
    product: null,
    issue_day: null,
    issue_date_before: null,
    issue_date_after: null,
    file_generated_after: null,
    file_generated_before: null,
    filtersObserver: Ember.on('init', Ember.observer('filters.print_site', 'filters.document_type', 'filters.product', 'filters.issue_day', 'filters.issue_date_before', 'filters.issue_date_after', 'filters.file_generated_after', 'filters.file_generated_before', function () {
      var _this = this;

      this.setProperties(this.filters);
      this.set('issue_day', this.days_of_week.find(function (d) {
        return d.value === parseInt(_this.get('filters.issue_day'));
      }) || this.days_of_week[0]);
      this.set('document_type', this.document_types.find(function (d) {
        return d.value === _this.get('filters.document_type');
      }) || this.document_types[0]);
      this.set('print_site', this.print_sites.find(function (d) {
        return d.value === _this.get('filters.print_site');
      }) || this.print_sites[0]);
    })),
    days_of_week: [{
      name: 'All',
      value: null
    }, {
      name: 'Monday',
      value: 1
    }, {
      name: 'Tuesday',
      value: 2
    }, {
      name: 'Wednesday',
      value: 3
    }, {
      name: 'Thursday',
      value: 4
    }, {
      name: 'Friday',
      value: 5
    }, {
      name: 'Saturday',
      value: 6
    }, {
      name: 'Sunday',
      value: 0
    }],
    print_sites: [{
      name: 'All',
      value: null
    }, {
      name: 'APC',
      value: 'APC'
    }, {
      name: 'BPC',
      value: 'BPC'
    }, {
      name: 'DPC',
      value: 'DPC'
    }, {
      name: 'HPC',
      value: 'HPC'
    }, {
      name: 'MPC',
      value: 'MPC'
    }, {
      name: 'PPC',
      value: 'PPC'
    }, {
      name: 'RPC',
      value: 'RPC'
    }, {
      name: 'SPC',
      value: 'SPC'
    }, {
      name: 'TPC',
      value: 'TPC'
    }, {
      name: 'WPC',
      value: 'WPC'
    }, {
      name: 'YPC',
      value: 'YPC'
    }, {
      name: 'Unknown',
      value: 'UNKNOWN'
    }],
    document_types: [{
      name: 'All',
      value: null
    }, {
      name: 'Truck Manifest',
      value: 'Truck Manifest'
    }, {
      name: 'Labels',
      value: 'Labels'
    }, {
      name: 'Cart Notes',
      value: 'Cart Notes'
    }, {
      name: 'Run Figures Report',
      value: 'Run Figures Report'
    }, {
      name: 'Run Figures Summary',
      value: 'Run Figures Summary Report'
    }],
    actions: {
      clearFilters: function clearFilters() {
        this.setProperties({
          print_site: this.print_sites[0],
          document_type: this.document_types[0],
          product: null,
          issue_day: this.days_of_week[0],
          issue_date_before: null,
          issue_date_after: null,
          file_generated_after: null,
          file_generated_before: null
        });
      },
      applyFilters: function applyFilters() {
        this.onChange({
          print_site: this.get('print_site.value'),
          document_type: this.get('document_type.value'),
          issue_day: this.get('issue_day.value'),
          product: this.product,
          issue_date_before: this.issue_date_before,
          issue_date_after: this.issue_date_after,
          file_generated_after: this.file_generated_after,
          file_generated_before: this.file_generated_before
        });
      }
    }
  });

  _exports.default = _default;
});