define("gvp-portal/pods/components/print/filters-panel/date-range-selection/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field', 'is-horizontal', 'date-range'],
    calculatePosition: function calculatePosition(trigger) {
      var _trigger$getBoundingC = trigger.getBoundingClientRect(),
          top = _trigger$getBoundingC.top,
          left = _trigger$getBoundingC.left,
          width = _trigger$getBoundingC.width;

      return {
        style: {
          left: left + width,
          top: top + window.pageYOffset
        }
      };
    },
    actions: {
      selectRange: function selectRange(range) {
        switch (range) {
          case 'today':
            this.set('after', (0, _moment.default)().startOf('day').toDate());
            this.set('before', (0, _moment.default)().endOf('day').toDate());
            break;

          case 'yesterday':
            this.set('after', (0, _moment.default)().startOf('day').subtract(1, 'day').toDate());
            this.set('before', (0, _moment.default)().endOf('day').subtract(1, 'day').toDate());
            break;

          case 'week':
            this.set('after', (0, _moment.default)().startOf('week').add(1, 'day').toDate());
            this.set('before', (0, _moment.default)().endOf('week').add(1, 'day').toDate());
            break;

          case 'month':
            this.set('after', (0, _moment.default)().startOf('month').toDate());
            this.set('before', (0, _moment.default)().endOf('month').toDate());
            break;

          case 'year':
            this.set('after', (0, _moment.default)().startOf('year').toDate());
            this.set('before', (0, _moment.default)().endOf('year').toDate());
            break;

          default:
            break;
        }
      }
    }
  });

  _exports.default = _default;
});