define("gvp-portal/models/password-reset", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    reset_token: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('length', {
      min: 5
    })],
    password_confirmation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    })]
  });

  var _default = Ember.Object.extend(Validations, {
    reset_token: null,
    password: null,
    password_confirmation: null
  });

  _exports.default = _default;
});