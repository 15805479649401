define("gvp-portal/services/sentry", ["exports", "ember-cli-deploy-sentry/services/raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    unhandledPromiseErrorMessage: '',
    captureException: function captureException()
    /* error */
    {
      this._super.apply(this, arguments);
    },
    captureMessage: function captureMessage()
    /* message */
    {
      return this._super.apply(this, arguments);
    },
    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },
    ignoreError: function ignoreError() {
      return this._super();
    },
    callRaven: function callRaven()
    /* methodName, ...optional */
    {
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});