define("gvp-portal/pods/components/print/documents-table/download-links/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    config: Ember.inject.service(),
    downloadUrl: Ember.computed('config.api.host', 'session.data.authenticated.access_token', 'value', function () {
      return "".concat(this.get('config.api.host'), "/documents/").concat(this.value, "/download?access_token=").concat(this.get('session.data.authenticated.access_token'));
    }),
    viewUrl: Ember.computed('config.api.host', 'session.data.authenticated.access_token', 'value', function () {
      return "".concat(this.get('config.api.host'), "/documents/").concat(this.value, ".pdf?access_token=").concat(this.get('session.data.authenticated.access_token'));
    })
  });

  _exports.default = _default;
});