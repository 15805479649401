define("gvp-portal/pods/settings/users/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      controller.fetchUsers.perform();
    },
    actions: {
      reloadUsers: function reloadUsers() {
        this.controller.get('fetchUsers').perform();
      }
    }
  });

  _exports.default = _default;
});