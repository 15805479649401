define("gvp-portal/router", ["exports", "gvp-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('auth', function () {
      this.route('login');
      this.route('reset-password');
      this.route('expired-password');
    });
    this.route('print');
    this.route('settings', function () {
      this.route('account', function () {
        this.route('view', {
          path: '/'
        });
        this.route('edit', {
          path: '/edit'
        });
      });
      this.route('users', function () {
        this.route('view', {
          path: '/:id'
        });
        this.route('edit', {
          path: '/:id/edit'
        });
        this.route('create', {
          path: '/add'
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});